import ctl from "@netlify/classnames-template-literals"
import React from "react"
import Container from "../../utils/container"

const MissionStatement = () => {
  const blockquote_style = ctl(`
  flex 
  my-12  
  items-start
  `)

  const quotation_mark_style = ctl(`
  flex-shrink-0 
  md:mr-8 
  mr-5 
  md:w-11 w-6
  `)

  const quotation_text_style = ctl(`
  md:text-3xl 
  text-lg 
  font-semibold 
  sm:pt-4 
  pt-1
  `)
  return (
    <section className="relative">
      <Container>
        <p>
          At FourthCanvas, we are inspired by emerging brands who disregard the
          ‘disadvantages’ of their novelty but double down on the
          irresistibility of their innovation, and communicate this
          distinctively through the ways they present themselves and engage
          people via new media. These brands take every opportunity to embrace
          disruption, etch a place in the minds of people, warm their hearts and
          earn not only patronage but love, thereby achieving sustained success
          and overtaking the ‘giants’.
        </p>

        <div className={blockquote_style}>
          <svg
            viewBox="0 0 42 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={quotation_mark_style}
          >
            <rect width="42" height="65" fill="#557FCA" />
          </svg>

          <h3 className={quotation_text_style}>
            We hope to, through #AfricaChallengerBrands, advance the
            conversation on the relationship between intangible brand value and
            business success, thereby aiding more brands to emerge and disrupt
            the status quo across sectors.
          </h3>
        </div>
        <p>
          To inspire African brands to be more intentional with their mission,
          communication, distinction and overall appeal, by shedding light on
          the best examples and what they have been able to achieve as a result.
        </p>

      </Container>
    </section>
  )
}

export default MissionStatement
