import React from "react"

import Layout from "../components/layout"
import MissionStatement from "../components/pages/mission/statement"
import PageHeader from "../components/utils/page-header"

const Mission = ({ location }) => {
  return (
    <Layout title="Mission" url={location.pathname}>
      <PageHeader topText="Beating" bottomText="the giants" title="Mission" />
      <MissionStatement />
    </Layout>
  )
}

Mission.propTypes = {}

export default Mission
